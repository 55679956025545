import React from 'react';
import Img from "gatsby-image"
import { Link } from "gatsby";

const ProductoMini = ({producto}) => {
    const { imagen,titulo ,subtitulo,slug} = producto.producto;
 

  const miniImage = imagen.fluid;
    return (
        <li className="sub-item-li sub-item-li-active">
          <Link  
                 style={{ color: "white " }}
            activeClassName="active"           
            aria-label="Navegar"
            to={`/productos/${slug}`}> 
             <Img className="sub-li-img" style={{ width: "3.7rem", height: "3.7rem", objectFit:"cover"}} fluid={miniImage} alt="llaves" loading="lazy" />
            <span className="sub-li-spec-span">
            <p className="font-size-14">{titulo}</p>
            <p className="light">{subtitulo}</p>
            </span>
            </Link>
            </li>
    );
};

export default ProductoMini;