import{graphql,useStaticQuery} from 'gatsby';

const useProductos = () => {
    const data=useStaticQuery(graphql`query  {
        allDatoCmsProducto{
          nodes{
            imagen{
              fluid(maxWidth: 600) {
                  ...GatsbyDatoCmsFluid
              }
            }
            slug
            subtitulo
            titulo
            categoria
            productobandera
            
          }
        }
      }
      `);
      const info=data.allDatoCmsProducto.nodes.map((producto,index)=>({
          producto
      }));
    return info;
};

export default useProductos;