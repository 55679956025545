import React, { useState } from "react"
import ProductoMini from "../Producto/ProductoMini";
import useProductos from '../hooks/useProductos';
import Img from "gatsby-image"
import { graphql, useStaticQuery, Link } from "gatsby"
const NewHeader = () => {
    const { image1} = useStaticQuery(graphql`
    query myqueryLogo2 {
     
      image1: file(relativePath: { eq: "logo.png" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 578, maxHeight: 426) {
          ...GatsbyImageSharpFluid
         
          }
        }
      }
    }
  `)
  
  const instragramImage = [image1.sharp.fluid]
    const info = useProductos();
    const [isActive, setActive] = useState(true);
    let productofilter1 = info.filter(producto => producto.producto.categoria === "smartplug");
    //let productofilter2 = info.filter(producto => producto.producto.categoria === "uremote");
    let productofilter3 = info.filter(producto => producto.producto.categoria === "smartlight");
    let productofilter4 = info.filter(producto => producto.producto.categoria === "smartsensors");
    let productofilter5 = info.filter(producto => producto.producto.categoria === "smartswitch");
    const toggleClass = () => {
      setActive(!isActive);
    };
    const onHover = () => {
      setActive(!isActive);
    };
  return (
    <nav
      className="nav navbar-expand-lg navbar-light pt-4 pb-4"
      style={{  backgroundColor: "#000"}} >
      
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <div className="logo"><Link to="/">
          <span>
        <Img loading="lazy" style={{ width: "7.5rem", height: "5.5rem"}} fluid={instragramImage} alt="logo Madtronix" /></span></Link>
        </div>
        <ul className="navbar-nav m-auto" >
        <li>
          <Link
            style={{ color: "#a4a4a4 " }}
            activeClassName="active"
            className="nav-item nav-link"
            aria-label="Navegar al Inicio"
            to="/"
          >INICIO
            
          </Link></li>
          <li    style={{ color: "#a4a4a4 " }} className="nav-item nav-link"> <a className="has-icon" onClick={toggleClass} id="modalProducts">Productos</a>
                  <div className={isActive ? 'sub-nav sub-nav-noshow' : 'sub-nav sub-nav-show'} onMouseLeave={onHover}  >
                    < div className="sub-spec">
                      <div className="sub-spec-content">
                      <div className="sub-spec-item"><span className="sub-spec-title">SMART LIGHTING</span> <ul className="sub-item-ul sub-spec-item-ul">
                          {
                            productofilter3.map((producto, index) =>
                              (<ProductoMini key={index} producto={producto} />)
                            )

                          }
                        </ul></div>
                        <div className="sub-spec-item"><span className="sub-spec-title">SMART PLUG</span> <ul className="sub-item-ul sub-spec-item-ul">
                          {
                            productofilter1.map((producto, index) =>
                              (<ProductoMini key={index} producto={producto} />)
                            )

                          }

                        </ul></div>
                       
                       
                        <div className="sub-spec-item"><span className="sub-spec-title">SMART SWITCH</span> <ul className="sub-item-ul sub-spec-item-ul">
                          {
                            productofilter5.map((producto, index) =>
                              (<ProductoMini key={index} producto={producto} />)
                            )

                          }
                          
                        </ul></div>
                        <div className="sub-spec-item"><span className="sub-spec-title">SMART SENSORS </span> <ul className="sub-item-ul sub-spec-item-ul">
                          {
                            productofilter4.map((producto, index) =>
                              (<ProductoMini key={index} producto={producto} />)
                            )

                          }
                          
                        </ul></div>
                 
                      </div>
                    </div>
                  </div>
                </li>
         

          <li>  <Link
            style={{ color: "#a4a4a4" }}
            activeClassName="active"
            className="nav-item nav-link"
            aria-label="Navegar a la Mision y Vision"
            to="/misionvision/"
          >MISION & VISION
          </Link></li>
          <li> <Link
            style={{ color: "#a4a4a4" }}
            activeClassName="active"
            className="nav-item nav-link"
            aria-label="Navegar a Contactarme"
            to="/contacto/"
          > CONTACTO
           
          </Link></li>

          <li><Link
            style={{ color: "#a4a4a4" }}
            activeClassName="active"
            className="nav-item nav-link"
            aria-label="Navegar a Nosotros"
            to="/nosotros/"
          >NOSOTROS
            
          </Link></li>

   
        </ul>
   
      </div>
    </nav>
   
  )
}

export default NewHeader